%wrap-style{
    border-radius: 5px;
    background-color: var(--base-color);
    box-shadow: 1px 1px 9px 0 rgba(0,0,0,0.15);
}


.product-card-vertical{
    box-shadow: 1px 3px 9px 0 rgba(0,0,0,0);
    transition: all 0.3s ease-in-out;
    position: relative;
    z-index: 0;

    .product-cover{
        @extend %wrap-style;
        overflow: hidden;
    }

    .product-description{
        position: relative;
        text-align: center;
        padding: 10px 12px 0;
        margin-bottom: 0;
        transition: all 0.3s ease-in-out;
        border-top: 1px solid transparent;
        z-index: 3;

        h5{
            color: var(--text-color);
            line-height: 1.5em;
        }

        .status{
            font-size: 12px;
            font-weight: bold;
            text-transform: uppercase;
            color: var(--primary-color-hex);
        }

    }

    .product-actions {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .link-button {
            font-size: 13px;
            width: 110px;
            min-width: 100px;
            padding: 0 15px;
        }

        .to-favorite {
            font-size: 32px;
            color: var(--sub-text-color);
            transition: all 0.3s ease-in-out;

            &:hover {
                color: var(--text-color);
            }
        }
    }

    &.hover {
        .product-actions {
            transition: all 0.3s ease-in-out;
            position: absolute;
            bottom: 10px;
            left: 10px;
            right: 10px;
            opacity: 0;

            &-wrap {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                transition: all 0.3s ease-in-out;
                z-index: 2;
                overflow: hidden;
            }


        }

        &:hover{
            z-index: 4;

            .product-cover{
                box-shadow: 1px 3px 9px 0 rgba(0,0,0,0);
            }

            .product-description{
                border-top: 1px solid var(--default-button-color-hex);
                background-color: var(--base-color);
            }

            .product-actions{
                bottom: 10px;
                opacity: 1;

                &-wrap{
                    bottom: -60px;
                    @extend %wrap-style;
                }
            }

        }

    }

    &:not(.hover){
        @extend %wrap-style;
        overflow: hidden;

        .product-description{
            border-top: 1px solid var(--default-button-color-hex);
            background-color: var(--base-color);

            h5{
                min-height: 42px;
            }
        }

        .product-actions{
            padding: 10px;

            &-wrap{
                position: relative;
                top: 0;
                left: 0;
                right: 0;
                z-index: 2;
                background-color: var(--base-color);
            }
        }
    }

    .product-cover{
        padding-bottom: 100%;
        position: relative;
        display: block;
        transition: all 0.3s ease-in-out;
        z-index: 3;


        & > *{
            position: absolute;
            left: 0;
            top: 0;
        }

        img{
            object-fit: contain;
            width: 100%;
            height: 100%;
        }

        .anticon{
            font-size: 120px;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            color: var(--sub-text-color);
        }
    }
}

