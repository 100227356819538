.product-media-video-form{
    width: 500px;

    &-wrap{
        position: relative;
        top: 7%;
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .product-media-cover{
        border: 1px dashed var(--sub-text-color);
        width: 500px;
        height: 500px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        & > * {
            width: inherit;
            height: inherit;
        }
    }
}

.product-media{

    &-video{
        transform: translateY(-50px);
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        iframe{
            width: 100%;
            height: 100%;
            max-width: 800px;
            max-height: 450px;
        }
    }


}