.showroom-header{
    min-height: 520px;
    padding-bottom: 20px;

    &-wrap{
        background: linear-gradient(0deg, #FFFFFF 35.13%, #D8D8D8 100%);
    }

    &-logo{
        position: relative;
        margin: -40px 0 0;
        padding-left: 35px;
        max-width: calc(100% - 250px);

        .avatar-image{
            background-color: var(--base-color);
            border-radius: 5px;

            .ant-avatar{
                box-shadow: 1px 2px 7px 0 rgba(0,0,0,0.19);
                border: none !important;

            }
        }

        .avatar-content{
            margin-top: 40px;
            margin-left: 20px;
        }

        .to-favorite{
            position: absolute;
            right: -230px;
            top: 85px;
        }
    }

    &-upload{
        position: absolute;
        right: 40px;
        bottom: 24px;

        .item-loader{
            .anticon{
                font-size: 20px;
                margin-right: 10px;
            }
        }

        .anticon{
            font-size: 30px;
        }
    }
}