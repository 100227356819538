.search-form{
    display: flex;
    flex: 1;
    max-width: 500px;

    &-type{
        &.one-item{
            cursor: default;
        }
    }

    .search-type-wrap{
        position: relative;


        .search-icon-wrap{
            display: flex;
            align-items: center;
            z-index: 1;
            padding: 0 5px;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;

            .anticon{
                font-size: 36px;
                color: var(--primary-color-hex);
            }
        }

        & > .ant-select {

            .ant-select-arrow{
                font-size: 36px;
                color: var(--primary-color-hex);
                top: 1px;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                margin-top: 0;
                z-index: 2;

                button{
                    padding: 0;
                    background-color: transparent;
                    outline: none;
                    border: none;
                    cursor: pointer;
                    width: inherit;
                    height: inherit;

                    .anticon{
                        width: inherit;
                        height: inherit;
                        display: flex;
                        justify-content: flex-end;
                    }
                }
            }

            .ant-select-selector {
                border-radius: 4px 0 0 4px !important;
                border: none !important;
                box-shadow: none !important;
                height: 41px !important;
                padding-left: 50px !important;
                outline: none !important;

                .ant-select-selection-item {
                    font-weight: bold;
                    color: var(--primary-color-hex);
                }
            }

            &:not(#{&}-disabled).ant-select-focused{
                .ant-select-selector {
                    box-shadow: none !important;
                }
            }
        }
    }

    .ant-input{
        background-color: transparent;
        color: var(--base-color);
        border-radius: 0 4px 4px 0 !important;
        box-shadow: none !important;

        &::placeholder{
            color: var(--base-color);
            opacity: 0.5;
        }

        &:focus,
        &:hover{
            border-color: var(--base-color) !important;
        }
    }

    .search-type-wrap{
        display: flex;
    }
}

.search-form-type-dropdown{
    top: 60px !important;
    padding: 5px !important;
    position: fixed !important;

    .ant-select-item{
        border-radius: 16px;
        transition: all 0.3s ease-in-out;

        &-option-selected{
            background-color: transparent !important;
        }

        &:hover{
            background-color: var(--sub-bg-color-hex) !important;
        }
    }
}