.main-menu{
    height: 60px;
    z-index: 999;
    display: flex;
    transition: all 0.3s ease-in-out;
    color: var(--base-color);
    justify-content: space-between;
    padding: 0 30px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);

    &.fixed{
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        z-index: 1000;
    }

    &.off{
        & > *{
            display: none;
        }
    }

    &.holder-off{
        display: none;
    }

    & > .right-side,
    & > .left-side{
        display: flex;
        align-items: center;
    }

    & > .right-side{
        justify-content: flex-end;

        &  > * {
            display: flex;
            align-items: center;

            & + *{
                margin-left: 12px;
            }
        }
    }

    & > .left-side{
        flex: 1;
    }

    .menu-button{
        line-height: 1.2em;
        display: flex;
        align-items: center;
        transition: all 0.3s ease-in-out;
        color: var(--base-color);
        border-radius: 50%;

        &.home{
            margin-right: 8px;
        }

        .anticon{
            font-size: 34px;
            padding: 4px;
        }

        &:hover{
            text-decoration: none;
            color: var(--base-color);
            background-color: rgba(255, 255, 255, 0.25);
        }

        &.active{
            background-color: rgba(0, 0, 0, 0.2);
        }
    }

}