.analytics-summary-list{
    display: flex;

    & > *{
        flex: 1;
        background-color: var(--base-color);
        border: 1px solid var(--primary-bg-color-hex);
        box-shadow: 1px 3px 7px 0 rgba(0,0,0,0.1);
        padding: 15px 15px 20px;
        border-radius: 4px;

        & > *{
            padding-left: 40px;
        }

        .label{
            color: var(--sub-text-color);
            font-size: 14px;
            position: relative;
            display: flex;
            align-items: center;
        }

        .count{
            color: var(--text-color);
            font-size: 36px;
            margin-top: 15px;
        }

        .check-mark{
            .anticon{
                position: relative;
                color: var(--success-color);
            }
        }

        .anticon{
            font-size: 34px;
            position: absolute;
            left: 0;
        }
    }

    & > * + *{
        margin-left: 20px;
    }

    &.compact{

        & > *{
            padding: 10px;

            & > *{
                padding-left: 0;
            }

            .label{
                font-size: 12px;
                line-height: 1em;
                align-items: flex-start;
            }

            .check-mark{
                .anticon{
                    top: 3px;
                }
            }

            .count{
                font-size: 18px;
                margin-top: 5px;
                line-height: 1em;
            }
        }

        .anticon{
            font-size: 24px;
        }

        & > * + *{
            margin-left: 10px;
        }
    }
}