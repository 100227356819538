.menu-bar{

    ul{
        display: flex;
        padding: 0;
        margin: 0;
        list-style: none;

        li{
            & + li{
                margin-left: 10px;
            }
        }
    }

    a{
        color: var(--sub-text-color);
        font-size: 16px;
        display: flex;
        align-items: center;
        padding: 0 10px 8px;
        transition: all 0.3s ease-in-out;
        position: relative;

        &:after{
            content: "";
            position: absolute;
            left: 50%;
            width: 0;
            height: 2px;
            background-color: var(--primary-color-hex);
            transition: all 0.3s ease-in-out;
            bottom: 0;
        }


        .anticon{
            margin-right: 8px;
            font-size: 40px;
        }

        &:hover{
            color: var(--primary-color-hex);
        }

        &.active{
            color: var(--primary-color-hex);

            &:after{
                left: 0;
                width: 100%;
            }
        }
    }
}