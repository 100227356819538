.team-invite{

    &-modal{
        border-radius: 4px !important;
        overflow: hidden;

        .ant-form-item-explain-error{
            position: absolute;
            bottom: -25px;
        }

        .ant-modal-close{
            left: 10px;
            top: 10px;
        }

        .ant-modal-body{
            padding: 60px 30px 40px;
            text-align: center;

            h4{
                margin: 0;
            }

            form{
                margin-top: 30px;
            }
        }

    }

    &-button{
        display: flex !important;
        align-items: center !important;

        .anticon{
            font-size: 30px;
            height: 30px;
        }
    }

}