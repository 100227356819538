.showroom{

    &-control-page,
    &-page{

        h2{
            font-size: 20px;
            text-align: center;
            margin-bottom: 40px;
        }

        .block-loader{
            height: 300px;
            max-height: 100vh;
        }

        .ant-result{
            height: calc(100vh - 60px);
            display: flex;
            align-content: center;
            justify-content: center;
            flex-direction: column;
        }
    }

    &-header-wrap,
    &-content-wrap{
        & > *{
            max-width: 1220px !important;
        }
    }

    &-analytics-wrap,
    &-package-wrap,
    &-team-wrap,
    &-config-profile{
        padding: 105px 0 70px;

        .actions{
            margin-top: 50px;
        }

        .badge-page-label{
            top: -90px;
            min-width: 550px;
            text-align: center;
        }

        .packages-header{
            margin-bottom: 35px;

            h2{
                text-align: left;
                margin-bottom: 20px;
            }

            .current-package{
                font-size: 16px;

                b{
                    color: var(--primary-color-hex);
                }
            }
        }

        .team-header{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    &-analytics-wrap{
        padding: 0 0 70px;
        margin-top: 150px;
        position: relative;
        min-height: calc(100vh - 210px);

        .badge-page-label {
            top: -134px !important;
        }

        .analytics-menu-bar{
            position: relative;
            top: -48px;
        }

        .anticon{
            &.showroom{
                &-views{
                    color: var(--analytics-showroom-views-color);
                }

                &-favorites{
                    color: var(--analytics-showroom-favorites-color);
                }
            }

            &.product{
                &-views{
                    color: var(--analytics-product-views-color);
                }

                &-favorites{
                    color: var(--analytics-product-favorites-color);
                }
            }

            &.document{
                &-views{
                    color: var(--analytics-document-views-color);
                }
            }

            &.video{
                &-views{
                    color: var(--analytics-video-views-color);
                }
            }
        }

        background-color: var(--sub-bg-color-hex);
    }

    &-content-wrap{
        background-color: var(--sub-bg-color-hex);
        padding-top: 40px;
        padding-bottom: 90px;
        min-height: calc(100vh - 60px  - 530px);

        & > .ant-col{
            padding: 0 34px;
        }
    }

    &-communication-page{
        .no-users{
            text-align: center;
            padding: 30px 0 50px;
            font-size: 16px;
            font-weight: bold;
        }
    }
}

.showroom-config-profile{
    & > *{
        max-width: 1000px !important;
    }
}
