.product-media-type{
    &-form {
        position: absolute;
        top: 32px;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;

        .ant-form-item{
            margin: 0;
        }
    }

}