.product-author-content{
    margin-top: 30px;
    display: flex;

    .text{
        min-height: 150px;
    }

    .avatar-image img{
        object-fit: cover;
    }
}