.product-create-block{
    margin: 26px 25px 20px 35px !important;
    box-shadow: 1px 3px 12px 0 rgba(0,0,0,0.14);

    .ant-collapse-header{

        padding: 16px 54px 16px 24px !important;

        .header-content{
            display: flex;
            align-items: center;
            justify-content: space-between;

            h4{
                margin: 0;
            }

            p{
                color: var(--sub-text-color);
            }

            span{
                font-weight: bold;
                margin-right: 3px;
                color: var(--primary-color-hex);
            }
        }

        .ant-collapse-arrow{
            font-size: 34px !important;
            right: 10px !important;
            padding: 5px 0 !important;
        }


    }

    .ant-collapse-item{

        .ant-collapse-arrow{
            transform: rotate(0deg);
            transition: all 0.3s ease-in-out;
        }

        &-active{

            .ant-collapse-arrow{
                transform: rotate(-180deg);

            }

        }
    }

    .ant-collapse-content-box{
        padding: 0 24px 20px 24px !important;
    }
}

.product-create-button-wrap{
    display: flex;

    & > *{
        flex: 1;

        & + *{
            margin-left: 17px;
        }
    }

}