.product-create-button{
    min-height: 80px;
    border: 1px dashed var(--sub-text-color);
    background: var(--default-shapes-color);
    color: var(--sub-text-color);
    padding: 5px 13px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    outline: none;
    text-align: left;

    .anticon{
        font-size: 68px;
        margin-right: 10px;
        color: var(--sub-text-color);
        transition: all 0.3s ease-in-out;
    }

    .item-loader{
        margin: 0 12px;

        .anticon{
            font-size: 32px;
        }
    }

    &:hover{
        border-color: var(--text-color);
        color: var(--text-color);

        .anticon{
            color: var(--text-color);
        }
    }
}