.user-profile-modal{
    .ant-modal-content{
        border-radius: 4px;
    }
}

.user-profile-modal-button{
    &.ant-btn-ghost{
        text-transform: none !important;
        height: 34px;
        box-shadow: none;
    }
}