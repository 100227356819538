.search-terms{
    display: flex;
    flex-wrap: wrap;

    &-wrap{
        padding: 20px 0;
        background-color: var(--base-color);
    }

    & > *{
        margin: 8px 5px;
    }

    .term-link{

        text-transform: initial;
        padding: 0 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid  var(--default-button-color-hex);
        height: 36px;
        border-radius: 20px;
        transition: all 0.3s ease-in-out;

        b{
            margin-right: 3px;
        }

        &:hover{
            color: var(--text-color);
            background-color: var(--default-button-color-hex);
        }

        &.active{
            color: var(--base-color);
            border-color: var(--primary-color-hex);
            background-color: var(--primary-color-hex);
        }
    }

    .more-button{
        text-transform: none;
        font-weight: bold;
        height: 36px;
        border: none !important;
        color: var(--primary-color-hex);
        background-color: initial !important;

        &:hover{
            color: var(--text-color);
        }
    }
}