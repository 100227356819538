.product-author-slider{

    display: flex;
    justify-content: center;

    .swiper-slide{
        width: 178px;
        margin: 0 10px;
    }

    .slick-arrow{
        position: absolute;
        z-index: 10;
    }


    &-wrap{
        padding: 25px 0;
        background: var(--base-color);
        min-height: 330px;
        overflow: hidden;

        & > h2{
            margin-bottom: 34px;
            text-align: center;
        }
    }

    .avatar-image img{
        object-fit: cover;
    }

    &.short{
        .slick-list{
            justify-content: center;
            display: flex;

            .slick-track{
                justify-content: center;
                display: flex;
            }
        }
    }


    .slick-arrow{
        top: 35% !important;

        &.slick-next{
            right: 15px;
        }

        &.slick-prev{
            left: 15px;
        }
    }
}