.exhibition-stands{
    height: calc(100vh - 60px);

    .expo-logo{
        position: absolute;
        top: 12%;
        left: 50%;
        height: auto !important;
        transform: translate(-50%, 0px);
        max-width: 300px;
        transition: all 0.6s;
        opacity: 1;

        &.hide{
            top: -100px !important;
            opacity: 0;
            max-width: 100px;
        }

        img{
            max-width: 100%;
        }
    }

    & > div{
        height: 100% !important;

    }

    & > .ee-stand{
        display: none !important;
    }

    .ee-stand{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 200px;
        height: 180px;
        text-decoration: none !important;
        padding: 30px;
        box-sizing: border-box;
        pointer-events: auto !important;

        &::before {
            position: absolute;
            width: 100%;
            height: 100%;
            content: "";
            border: 2px solid #FFFFFF;
            background-color: rgba(255,255,255,0.95);
            box-shadow: 0 0 10px 0 rgba(var(--card-glow-color), 0.15);
            border-radius: 4px;
            z-index: -1;
            transition: all 0.3s ease-in-out;
        }

        &:hover {
            &::before {
                transform: scale(1.15);
                box-shadow: 0 0 15px 0 rgba(var(--card-glow-color), 0.15);
            }

            .logo {
                transform: scale(1.15);
            }
        }

        .logo{
            max-height: 120px;
            max-width: 140px;
            transition: all 0.3s ease-in-out;
        }
    }

    .item-loader{
        display: flex;
        align-items: center;
        justify-content: center;

        .anticon{
            font-size: 32px;
        }
    }

}