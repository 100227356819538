.showroom-control-menu{

    .menu-block{
        margin-bottom: 40px;

        .menu-label{
            font-size: 12px;
            text-transform: uppercase;
            color: var(--sub-text-color);
            font-weight: bold;
            margin-bottom: 10px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            padding: 0 10px;
            width: 100%;
            transition: all 0.3s ease-in-out;
        }

    }

    .menu{
        list-style: none;
        padding: 0;
        margin: 0;

        .ant-btn-default{
            position: relative;
            padding: 0 12px;
            font-size: 14px !important;
            width: 250px;
            transition: all 0.3s ease-in-out;

            .anticon{
                padding: 3px;
                background: none !important;
            }

            span:not(.anticon){
                margin-left: 10px;
            }

        }

        .btn-wrap{
            margin: 10px -11px 0;
            position: relative;
            transition: all 0.3s ease-in-out;
        }

        & > li:not(.btn-wrap){
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            margin-top: 2px;
        }

        a{
            font-size: 16px;
            display: flex;
            align-items: center;
            color: var(--text-color);

            span:not(.anticon){
                margin-left: 8px;
            }

            .anticon{
                font-size: 30px;
                padding: 5px;
                transition: all 0.3s ease-in-out;
                border-radius: 50%;
            }

            &:hover{
                .anticon{
                    background-color: var(--primary-bg-color-hex);
                }
            }

            &.active{

                .anticon{

                    color: var(--base-color);
                    background-color: var(--primary-color-hex);
                }
            }
        }

    }


}

.sidebar.disabled{

    .menu{
        a:hover{

            .anticon{
                background-color: transparent !important;
            }
        }

    }
}

.sidebar:not(.open){

    .menu-label{
        width: 0;
        padding: 0;
    }

    .menu{
        .btn-wrap{
            margin: 10px 0 0;
        }

        .ant-btn-default{
            min-width: 40px;
            width: 40px;
            padding: 1px;
        }
    }

}