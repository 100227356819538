.avatar-image{

    &.with-image > .ant-avatar{
        border: 1px solid var(--default-button-color-hex);
        background-color: var(--base-color);
    }

    .ant-avatar{
        background-color: var(--default-button-color-hex);
        border: 1px dashed var(--sub-text-color);
        display: flex;
        align-items: center;

        img{
            object-fit: contain;
        }
    }

    &.no-image{
        & > .ant-avatar{
            color: var(--sub-text-color);
            background: var(--default-button-color-hex);
            border: none;
        }
    }

    .ant-avatar-square{
        border-radius: 4px;
    }
}