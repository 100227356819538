.communication-create{

    &-wrap{
        text-align: center;
        margin-bottom: 60px;

        .in-package{
            color: var(--primary-color-hex);
            text-align: right;
            margin-bottom: 5px;

            .sub-text{
                font-weight: normal;
            }
        }

        .communication-create-button{
            background-color: var(--primary-bg-color-hex) !important;
            border: 1px dashed var(--sub-text-color) !important;
            color: var(--sub-text-color) !important;
            border-radius: 4px !important;
            width: 100%;
            height: 90px;
            display: flex;
            justify-content: center;
            margin-bottom: 5px;
            font-weight: bold;
            text-transform: none;

            .item-loader{
                .anticon-loading{
                    font-size: 32px !important;
                    margin-right: 15px;
                }

                .spin-bg:before{
                    width: 32px;
                    height: 32px;
                }
            }


            &:before{
                border: 1px dashed var(--sub-text-color) !important;
            }

            .anticon{
                font-size: 60px;
            }

        }
    }

}