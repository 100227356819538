.team-list{

    & > * + *{
        margin-top: 14px;
    }

    &-wrap{
        margin-top: 35px;
    }

    &-item{
        padding: 12px 14px;
        margin-top: 10px;
        display: flex;
        box-shadow: 1px 3px 7px 0 rgba(0,0,0,0.1);
        border: 1px solid var(--primary-bg-color-hex);
        border-radius: 4px;
        transition: all 0.2s ease-in-out;
        position: relative;
        left: 0;
        top: 0;

        &:hover{
            box-shadow:1px 0 3px 0 rgba(0,0,0,0.1);
            border: 1px solid var(--second-sub-text-color);
            left: 2px;
            top: 2px;
        }

        .avatar-content{
            line-height: 1.2em;
        }

        & > *{
            flex: 1;
        }

        .right-side{
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .permission-control{
                display: flex;
                align-items: center;
            }

            .field-group{
                display: flex;

                & + .field-group{
                    margin-left: 30px;
                }
            }

            .permission-group{
                min-width: 170px;

                .ant-select{
                    flex: 1;
                    min-width: 130px;
                }
            }


            .switch-group{
                align-items: center;

                .ant-switch{
                    box-shadow: none;

                    &-checked{
                        background-color: var(--success-color);
                    }

                }

                .switch-text{
                    color: var(--success-color);
                    line-height: 1em;
                    margin-left: 15px;
                    transition: all 0.3s ease-in-out;
                }

                &.off{
                    .switch-text{
                        color: var(--sub-text-color);
                    }

                    .ant-switch-checked{
                        background-color:var(--sub-text-color);
                    }
                }
            }

        }

    }

}