.expo-empty{
    width: 100%;
    height: calc(100vh - 60px);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--base-color);
    text-align: center;

    .expo-logo{
        margin-bottom: 50px;
    }

    h1{
        color: inherit;

        & + p{
            margin-top: 40px;
        }
    }

    a{
        color: inherit;
        text-decoration: underline;

        &:hover{
            color: inherit;
        }
    }
}