.package-list{
    display: flex;

    .package-item{
        flex: 1;
        margin-right: 20px;

        &:last-child{
            margin: 0;
        }
    }
}