@import '../../../scss/base/helpers';
@import '../../../scss/base/mixins';

.showroom-about-company{

    .description{
        font-size: 16px;
        line-height: 1.7em;
    }

    h1,
    .description{
        white-space: pre-line;
        overflow-wrap: break-word;
    }

    .media-wrap{
        margin: 25px 0;

        img{
            max-width: 100%;
        }

        iframe{
            width: 100%;
            min-height: 400px;
        }
    }

    .address-block{
        padding-left: 40px;
        position: relative;

        p + p{
            margin-top: 3px;
        }

        .anticon{
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    .contacts-block,
    .address-block{
        font-size: 16px;

        a{
            color: var(--primary-color-hex);

            &:hover{
                text-decoration: underline;
            }
        }
    }

    .contacts-block{
        margin-top: 40px;

        @extend %list-reset;

        li{
            display: flex;
            align-items: center;
            padding-left: 40px;
            position: relative;

            & + li{
                margin-top: 10px;
            }

            a{
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }

            .anticon{
                position: absolute;
                left: 0;
            }
        }
    }

    .social-links-block{
        margin-top: 30px;
        display: flex;

        @extend %list-reset;

        li + li{
            margin-left: 10px;
        }

        .anticon{
            color: var(--base-color);
        }

        a{
            display: block;
            width: 40px;
            height: 40px;
            padding: 5px;
            border-radius: 50%;
            background-color: var(--primary-color-hex);
            position: relative;
            overflow: hidden;

            &:before{
                @include button-overlay(rgba(255, 255, 255, 0.2));
                @extend %transition;
            }

            &:active:before{
                @include button-overlay(rgba(0, 0, 0, 0.1));
            }

            &:hover{
                &:before {
                    opacity: 1;
                }
            }
        }
    }

    .address-block,
    .contacts-block,
    .social-links-block{
        .anticon{
            font-size: 30px;
        }
    }
}