@import '../../scss/base/helpers';

.app{
    width: 100%;
    min-height: 100vh;
    position: relative;
    left: 0;
    overflow-x: hidden;
    @extend %transition;

    max-width: calc(100% - var(--app-offset, 0));

    .block-loader{
        width: inherit;
        min-height: inherit;
    }

    .main-block{
        width: 100%;
        min-height: 100vh;
        padding-top: 60px;
        max-width: calc(100% - var(--main-offset, 0));
        @extend %transition;

        &.open-left{
            left: var(--main-offset);
        }
    }

    &.open-left{
        left: var(--app-offset);
    }

    & > .ant-result-500{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

