@import "scss/default";

.authorization-page{
    > * {
        min-height: 720px;
        height: 100vh;
    }

    .expo-image{
        width: 100%;
        height: calc( 100% - 200px );
        display: flex;
        overflow: hidden;
        position: relative;

        img{
            object-fit: cover;
            position: absolute;
            height: 100%;
            width: 100%;
        }
    }

    &.without-info{
        .expo-image{
            height: 100%;
        }

        .expo-info{
            display: none;
        }
    }


    a{
        color: var(--primary-color-hex);

        &:hover{
            color: var(--primary-color-hex);
            text-decoration: underline;
        }
    }

    .expo-info{
        background-color: var(--primary-color-hex);
        width: 100%;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;

        > * {
            position: relative;
            height: 130px;
            padding: 0 20px;

            & + *:before{
                content: "";
                height: 100%;
                width: 2px;
                background-color: var(--base-color);
                position: absolute;
                left: -1px;
                top: 0;
            }
        }

        .date{
            display: flex;
            justify-content: center;
            flex-direction: column;
            text-transform: uppercase;
            font-size: 26px;
            line-height: 1.2em;
            color: var(--base-color);
        }
    }

    .forms-col{
        box-shadow: 0 2px 8px 0 rgba(0,0,0,0.22);
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 450px;
        min-width: 360px;

        &.without-shadow{
            box-shadow: none;
        }

        .form-holder{
            max-width: 80%;
            width: 300px;
            min-width: 300px;

            h1{
                font-size: 18px;
                line-height: 1.4em;
                margin-bottom: 30px;
            }

            .form-actions{
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .sign-link-wrap{
                margin: 50px 0;

                span{
                    color: var(--sub-text-color);
                }

                > a{
                    margin-left: 5px;
                    display: inline-block;
                }
            }
        }

        .ant-btn-primary{
            text-transform: uppercase;
        }
    }

    .legacy-links-wrap{
        @extend .form-holder;
        position: absolute;
        bottom: 30px;

        ul {
            @extend %list-reset;

            width: 80%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            li{
                display: inline-block;

                :first-child{
                    padding-right: 10px;
                }

                &:nth-of-type(even){

                    position: relative;
                    padding-left: 10px;
                    line-height: 1em;

                    :before{
                        position: absolute;
                        content: "";
                        height: 1em;
                        left: 0;
                        width: 1px;
                        top: 0;
                        background-color: var(--sub-text-color);
                    }

                }


            }

            a{
                font-size: 13px;
                color: var(--primary-color-hex);

                &:hover{
                    text-decoration: underline;
                }
            }
        }
    }
}
