.product-author-form{
    display: flex;

    .upload-info{
        line-height: 1.2em;
        text-align: center;
        color: var(--sub-text-color);
        margin-top: -10px;
    }

    .image-wrap{
        .ant-form-item-explain-error{
            text-align: center;
        }
    }

    .photo-upload{
        cursor: pointer;

        .ant-avatar{

            img{
                object-fit: cover;
            }
        }

        .ant-avatar-string{
            display: flex;
            align-items: center;
        }

        .anticon:not(.anticon-spin){
            font-size: 100px;
            color: var(--sub-text-color);
        }
    }
}