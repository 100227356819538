.communication-modal{

    .ant-modal-close{
        color: var(--base-color);
        left: -35px;
        top: -35px;

        &:hover{
            color: var(--sub-text-color);
        }
    }

    .ant-modal-body{
        padding: 0;
    }

    #jitsiWrap{
        display: flex;
        align-items: center;
        justify-content: center;
    }
}