$sidebar-width : 280px;
$min-offset: 60px;

.showroom-control-sidebar{
    position: fixed;
    bottom: 0;
    left: 0;
    top: 60px;
    z-index: 999;
    padding: 24px 10px;
    box-shadow: 0 2px 11px 0 rgba(0,0,0,0.29);

    &.disabled{
        .menu{
            a:not(.active):hover{
                cursor: default;
                .anticon{
                    background-color: transparent;
                }
            }
        }

        .content{
            opacity: 0.4;
        }
    }

    .expand-button{
        right: -36px;
        top: 25px;
        border-bottom-right-radius: 7px;
        border-top-right-radius: 7px;
        font-size: 30px;
        padding: 0;
        transition: all 0.3s ease-in-out;

        .anticon{
            transition: all 0.3s ease-in-out;
        }

        &:hover{
            background-color: var(--primary-color-hex);
        }
    }

    .showroom-control-menu{
        margin-top: 36px;
    }

    .content{
        overflow: hidden;
        padding-left: 0;
        padding-right: 0;
        transition: all 0.3s ease-in-out;
    }

    .company-logo{
        overflow: hidden;

        .avatar-block{
            text-overflow: ellipsis;
            overflow: hidden;
            width: 230px;

        }

        .avatar-image {

            &.no-image {
                & > .ant-avatar {
                    font-size: 40px !important;
                    border-radius: 7px !important;
                }
            }

        }

    }


    .showroom-status-switcher{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        border-top: 1px solid var(--default-button-color-hex);
        padding: 24px 10px;
        text-align: center;
    }
}

.sidebar{
    --main-offset: #{$min-offset};
    left: var(--main-offset);
    position: relative;

    .showroom-control-sidebar{
        width: $min-offset;
        transition: all 0.3s ease-in-out;

    }

    &.open{
        --main-offset: #{$sidebar-width};

        .content{
            padding-left: 12px;
            padding-right: 13px;
        }

        .showroom-control-sidebar{
            width: $sidebar-width;
            left: 0 !important;
            padding-left: 13px;
            padding-right: 14px;
        }

        .showroom-status-switcher{
            padding-left: 24px;
            padding-right: 24px;
        }

        .expand-button{
            .anticon{
                transform: rotate(180deg);
            }

        }
    }

}

