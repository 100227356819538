.product-edit-form{
    overflow: auto;
    margin-bottom: 125px;
    padding: 0 50px !important;

    h2{
        margin-bottom: 40px;
    }

    .ant-switch {

        &-checked{
            background-color: var(--success-color);
        }

        &:after {
            content: attr(data-text);
            position: absolute;
            left: 65px;
            top: 0;
            width: 200px;
            text-align: left;
        }
    }
}