.card-company{
    height: 445px !important;

    &.active,
    &.flipped{
        height: 570px !important;
    }

    &.active{

        .card-text{
            height: 200px !important;

        }

        .company-description{
            height: 130px !important;
            -webkit-line-clamp: 6 !important;
        }

        .flip-button{
            right: -38px;

            &:hover{
                right: 0;
            }
        }
    }

    .card-inner-flip{

        .card-front{

            .company-logo{
                box-shadow: 1px 2px 7px 0 rgba(0,0,0,0.19);
                width: 170px;
                height: 170px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 5px;
                overflow: hidden;
                background-color: var(--base-color);

                img{
                    max-height: 100%;
                    max-width: 100%;
                }

                &-wrap{
                    border-bottom: 1px solid var(--default-button-color-hex);
                    height: 275px;
                    border-radius: 5px;
                    overflow: hidden;

                    background: linear-gradient(180deg, #CECECE 0%, #FFFFFF 100%);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                }

            }

            .card-actions{
                margin-top: 40px;
            }

            .card-text{
                height: 170px;
                text-align: center;
                transition: all 0.3s ease-in-out;

                h5{
                    height: 45px;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }

                .company-description{
                    transition: all 0.3s ease-in-out;
                    height: 90px;
                    display: -webkit-box;
                    -webkit-line-clamp: 4;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-align: center;
                }
            }
        }

    }

    .flip-button{
        position: absolute;
        right: -90px;
        top: 0;
        z-index: 10;
        transition: all 0.3s ease-in-out;
        font-size: 14px;
        color: var(--base-color);
        background-color: rgba(var(--text-color-rgb), 0.4);
        border: none;
        display: flex;
        align-items: center;
        border-radius: 0 4px 0 20px;
        padding-right: 10px;
        cursor: pointer;

        .anticon{
            font-size: 40px;
            padding-right: 5px;
        }
    }

    .company-products{
        height: 435px;
        border-radius: 5px;
        overflow: hidden;

        &.hide-nav{
            .swiper-pagination,
            .slick-arrow{
                display: none;
            }
        }

        .swiper-slide{
            width: 350px !important;
        }

        .product-photo{
            width: 350px;
            height: 350px;
            border-bottom: 1px solid var(--default-button-color-hex);
            display: flex;
            align-items: center;
            justify-content: center;

            img{
                max-width: 100%;
                max-height: 100%;
            }
        }

        h5{
            padding: 20px;
            text-align: center;
            height: 80px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        .swiper-pagination{
            bottom: 85px;

            &-bullet{
                background-color: var(--text-color);
                opacity: 0.25;
                width: 12px !important;
                height: 12px !important;
                transition: all 0.3s ease-in-out;
                border: 1px solid var(--base-color);

                &-active{
                    opacity: 1;

                }
            }
        }

        .slick-arrow{
            position: absolute;
            top: 40%;
            height: 36px !important;
            width: 36px !important;
            display: flex;
            align-items: center;
            justify-content: center;

            &.slick-next{
                right: 10px;

                .anticon{
                    left: 1px;
                }
            }

            &.slick-prev{
                left: 10px;

                .anticon{
                    right: 1px;
                }
            }

            .anticon{
                top: 0;
                font-size: 25px;
                position: relative;
            }
        }
    }

    .company-showroom{
        padding: 0 20px 22px;

        .avatar-content{
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }

}