.card-company-horizontal{
    .avatar-content{
        margin-left: 25px;

        h5{
            font-size: 16px;
            font-weight: bold;
            line-height: 1.2em;
            margin-bottom: 5px;
        }

        .sub-text-bold{
            line-height: 1.25em;
        }
    }
}
