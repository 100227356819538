%wrap-style{
    border-radius: 5px;
    background-color: var(--base-color);
    box-shadow: 1px 1px 9px 0 rgba(0,0,0,0.15);
}

.card{

    &-company,
    &-product{
        width: 275px;
        height: 375px;
        transition: all 0.3s ease-in-out;
        position: relative;

        .card-inner-flip{
            border-radius: 5px;
            position: relative;
            width: 100%;
            height: 100%;
            transition: transform 0.6s;
            -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
            box-shadow: 1px 3px 40px 0 rgba(0,0,0,0.15);
            background-color: var(--base-color);
            z-index: 0;

            .card-back{
                transform: translateZ(-1px) rotateY(180deg);
            }
        }

        &.active,
        &.flipped{
            z-index: 10;
            height: 535px;
            width: 350px;
            transform: translate(-10%, 0px);
        }

        &.flipped{
            .card-inner-flip {
                transform: rotateY(180deg);

            }
        }

        .card-actions{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 20px;

            .link-button{
                font-size: 13px;
                min-width: 100px;
                padding: 0 25px;
            }

            .to-favorite{
                font-size: 32px;
                color: var(--sub-text-color);
                transition: all 0.3s ease-in-out;

                &:hover{
                    color: var(--text-color);
                }
            }

        }

        .card-front{
            z-index: 0;
        }

        .card-front,
        .card-back{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            -webkit-perspective: 0;
            -webkit-backface-visibility: hidden;
            -webkit-transform: translate3d(0,0,0);
            visibility:visible;
            perspective: 0;
            backface-visibility: hidden;
            overflow: hidden;
        }

        .card-text{
            padding: 20px 20px;

            h5{
                text-align: center;
            }
        }
    }

    h5{
        font-weight: bold;
        font-size: 16px;
    }

    .popover-wrap{
        .popover-button{
            height: 40px;
            width: 40px;
            border: none;
            border-radius: 50%;
            font-size: 24px;
            background-color: transparent;
            color: var(--sub-text-color);
            transition: all 0.3s ease-in-out;
            cursor: pointer;

            &:hover,
            &.ant-popover-open{
                background-color: var(--default-shapes-color);
            }
        }
    }

    &-horizontal{
        position: relative;
        padding: 32px 48px 32px 30px;

        @extend %wrap-style;

        .actions-wrap{
            position: absolute;
            right: 10px;
            top: 10px;
            bottom: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

    }

    .badge{
        position: absolute;
        left: 7px;
        top: 7px;

        font-size: 14px;
        font-weight: bold;
        color: var(--sub-text-color);
        border: 1px solid var(--sub-text-color);
        border-radius: 13px;
        min-width: 90px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.primary{
            color: var(--primary-color-hex);
            border: 1px solid var(--primary-color-hex);
        }

    }

}