.analytics-block{
    h2{
        margin-bottom: 20px;
        text-align: left;
    }

    & + &{
        margin-top: 60px;
    }

    &.analytics-header + &{
        margin-top: 30px;
    }

    .empty{
        color: var(--sub-text-color);
        font-size: 18px;
        font-weight: bold;
        text-transform: uppercase;
        min-height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-chart{
        display: flex;

        .item-loader{
            min-height: 350px !important;
        }

        .chart-block{
            flex: 1;
            position: relative;

            .chart {
                display: block;
                width: 100%;
                height: 350px !important;
                background-color: var(--base-color);
                box-shadow: 1px 3px 7px 0 rgba(0,0,0,0.1);
                padding: 40px 25px 30px;
                border-radius: 4px;

                canvas {
                    height: 100% !important;
                    width: 100% !important;
                }
            }
        }

        & > * + *{
            margin-left: 20px;
        }
    }

    & > .item-loader{
        width: 100%;
        min-height: 125px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--default-shapes-color);
    }

    .analytics-export-wrap{
        right : 0;
        top: -45px;
        position: absolute;
    }

    .product-avatar{
        .ant-avatar{
            border-radius: 5px;
            background-color: var(--base-color);
            box-shadow: 1px 3px 9px 0 rgba(0,0,0,0.15);
        }

        .avatar-content{
            margin-left: 20px;
        }
    }

    .analytics-more{
        .anticon{
            color: var(--primary-color-hex);
            font-size: 32px !important;
        }
    }

    &.analytics-header{
        display: flex;
        justify-content: center;

        .product-avatar{

            .avatar-content{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 140px;
                flex: 1;

                h3{
                    font-size: 18px !important;
                }
            }

        }

        .back-button-wrap{
            position: absolute;
            left: 0;

            .anticon{
                transform: rotate(90deg);
                font-size: 30px;
            }
        }

        .total-data{
            width: 100%;
            max-width: 610px;

            .avatar-block{
                align-items: flex-start;

                .avatar-content{
                    margin-left: 40px;
                }

                .analytics-summary-list{
                    margin-top: 10px;

                    .label{
                        min-height: 2em;
                    }
                }
            }
        }
    }
}