.product-attachment-upload{
    border: 1px dashed var(--sub-text-color);
    color: var(--sub-text-color);
    height: 40px;
    width: 100%;
    text-align: left;
    background-color: var(--default-shapes-color);
    cursor: pointer;
    padding: 0 12.5px;
    display: flex;
    align-items: center;
    font-weight: bold;
    outline: none;
    transition: all 0.3s ease-in-out;

    &:hover{
        border-color: var(--primary-color-hex);
    }

    .item-loader{
        margin-left: 12px;
    }

    .anticon{
        font-size: 36px;
        margin-right: 10px;
    }

    &-wrap{
        display: block;
        width: 100%;

        .ant-upload{
            display: block !important;
        }
    }

}

.product-from-file-container{
    display: flex;
    align-items: center;
    color: var(--primary-color-hex);
    margin-bottom: 24px;


    .anticon{
        font-size: 36px;

    }

    .delete-file-button{
        padding: 0;
        min-width: auto;
        border: none;
        background-color: transparent !important;

        .anticon{
            transition: all 0.3s ease-in-out !important;
        }

        &:hover{

            .anticon{
                color: var(--primary-color-hex);
            }
        }
    }

}

.product-link-file-wrap{
    display: flex;
    align-items: center;
    flex: 1;
    color: var(--primary-color-hex);

    &:hover{
        color: var(--text-color);
    }

    .anticon{
        font-size: 36px;
        margin-right: 10px;

    }
}

.product-audio-file-wrap{
    flex: 1;
    color: var(--primary-color-hex);

    button{
        padding: 0;
        cursor: pointer;
        background: transparent;
        outline: none;
        border: none;
        display: flex;
        align-items: center;
        transition: all 0.3s ease-in-out !important;


        &:hover{
            color: var(--text-color);
        }

        .anticon{
            font-size: 36px;
            margin-right: 10px;
        }
    }
}