.product-author-preview{
    display: block;
    max-width: 178px;

    & > h4{
        font-size: 13px;
        margin: 13px 0 0;
        text-align: center;
        color: var(--sub-text-color);
    }

    .ant-avatar{
        display: flex;
        justify-content: center;
        transition: all 0.3s ease-in-out;
        border-color: transparent !important;

        .anticon{
            font-size: 100px;
        }
    }

    &:hover{
        .ant-avatar{
            border-color: var(--default-button-color-hex) !important;
        }
    }
}