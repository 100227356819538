$map-width : 300px;
$min-offset: 60px;

.exhibition-map{
    position: fixed;
    width: $map-width;
    top: 0;
    bottom: 0;
    right: calc(-#{$min-offset} - #{$map-width});
    padding: #{$min-offset};
    z-index: 1000;
    box-shadow: 0 2px 11px 0 rgba(0,0,0,0.29);

    .expand-button{
        left: -36px;
        top: 13px;
        border-bottom-left-radius: 7px;
        border-top-left-radius: 7px;
        font-size: 30px;
        transition: all 0.3s ease-in-out;
        padding: 0;


        .anticon{
            transform: rotate(180deg);
            transition: all 0.3s ease-in-out;
        }
    }

    .content{
        width: 100%;

        h2{

        }
    }
}

.expo-map{
    --app-offset: #{$min-offset};

    .exhibition-map{
        right: calc(#{$min-offset} - #{$map-width});
        transition: all 0.3s ease-in-out;
    }

    .main-menu{
        padding-right: 55px;
        right: var(--app-offset) !important;
    }

    &.open{
        --app-offset: #{$map-width};

        .main-menu{

            right: var(--app-offset) !important;
        }

        .exhibition-map{
            right: 0 !important;
        }

        .expand-button{
            .anticon{
                transform: rotate(0deg);
            }
        }

    }


}


