.product-author-modal{

    h2{
        text-align: center;
        margin: 5px 0 30px;
    }

    .ant-modal-body{
        padding: 55px 50px 60px;
        width: 840px;

        .image-wrap{
            max-width: 220px;
        }

        .description-wrap{
            margin-left: 50px;
            flex: 1;

            .title{
                margin-bottom: 30px;
                color: var(--text-color);
            }

            .text{
                font-size: 16px;
                line-height: 1.7em;
                color: var(--text-color);
                white-space: pre-line;
                overflow-wrap: break-word;
            }

            .action{
                margin-top: 40px;
                display: flex;
                justify-content: space-between;

                .ant-btn-default{
                    span{
                        margin: 0 auto;
                    }
                }
            }
        }
    }
}