.card-user-horizontal{
    .avatar-content{
        margin-left: 25px;

        h5{
            font-size: 16px;
            font-weight: bold;
            line-height: 1.2em;
            margin-bottom: 5px;
        }

        .sub-text-bold{
            line-height: 1.25em;
        }
    }

    .avatar-block{
        .sub-text-bold{
            text-transform: uppercase;
        }

        .avatar-block{
            margin-top: 17px;

            .avatar-content{
                margin-left: 10px;
            }

            h4{
                margin-bottom: 0;
                text-transform: none;
                font-size: 16px;
            }

            .sub-text{
                text-transform: capitalize;
            }


        }
    }

    h3{
        font-size: 16px;
        margin-bottom: 5px;
    }

    .user-role{
        margin-top: 60px;
    }

    .empty{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .anticon{
            font-size: 76px;
        }
    }
}
