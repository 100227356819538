#about-company{
    .company_title{
        textarea{
            font-size: 28px;
            text-transform: uppercase;
            line-height: 1.25em;
        }
    }

    .media-placeholder{
        min-height: 380px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--primary-bg-color-hex);
        border: 1px dashed var(--sub-text-color);
        position: relative;

        .anticon:not(.anticon-loading){
            font-size: 100px;
            color: var(--sub-text-color);
        }

        .label{
            font-size: 14px;
            font-weight: bold;
            text-align: center;
            color: var(--sub-text-color);
        }

        &.image{

            & > span{
               align-items: center;
            }

            button{
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }

            img{
                width: 100%;
                max-height: 100%;
                object-fit: contain;
            }
        }

        &.video{
            margin-bottom: 20px;

            iframe{
                min-height: inherit;
                width: inherit;
            }
        }
    }

    .upload-page-image{
        width: 100%;
        height: 100%;
        display: block;

        .ant-upload{
            cursor: pointer;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}