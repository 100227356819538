.card-product{

    .card-inner-flip{

        .card-front{
            .product-cover{
                border-bottom: 1px solid var(--default-button-color-hex);
                height: 275px;

            }

            .card-text{
                display: flex;
                align-items: center;
                height: 100px;
                justify-content: center;
            }
        }

        .card-back{

            .product-cover{
                max-height: 150px;

                img{
                    max-height: inherit;
                }
            }

            .card-text{

                h5{
                    height: 45px;
                    padding-bottom: 5px;
                }

                .product-description{
                    height: 155px;
                    display: -webkit-box;
                    -webkit-line-clamp: 7;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-align: center;
                }
            }
        }
    }

    .product-cover{
        transition: all 0.3s ease-in-out;
        display: flex;
        align-items: center;
        justify-content: center;


        img{
            max-width: 100%;
            max-height: 100%;
        }
    }

    .product-showroom{
        padding: 0 20px 22px;

        .avatar-content{
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }

}