.communication-join-button{
    margin: 20px auto 0;
    align-items: center;
    justify-content: center;
    display: flex !important;

    .item-loader{
        margin-right: 12px;

        .anticon{
            font-size: 24px;
            color: var(--base-color);
        }
    }

    .anticon{
        font-size: 30px;
    }
}