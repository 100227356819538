.communication-blocks{

    & > .item-loader{
        min-height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .conversation-block{

        padding: 20px;
        background-color: var(--primary-bg-color-hex);
        border: 1px solid var(--default-button-color-hex);
        border-radius: 4px;
        grid-column-gap: 40px !important;

        &-wrap{
            margin-bottom: 35px;

            h4{
                color: var(--sub-text-color);
                margin-bottom: 12px;
            }
        }

        .busy-text{
            margin-top: 20px;
            text-align: center;
        }

    }

    .communication-users{
        &.online{
            margin-bottom: 70px;
        }
    }

}