.search-page{
    height: calc(100vh - 60px);
    display: flex;
    align-items: center;
    min-height: 840px;

    h1{
        font-size: 16px;
        color: var(--base-color);
        text-transform: initial;
        text-align: center;
        margin-bottom: 50px;
    }

    .search-result-wrap{
        width: 100%;
        padding-top: 70px;

        .item-loader{
            color: var(--base-color);
            display: flex;
            justify-content: center;

            .anticon{
                font-size: 36px;
            }
        }
    }
}