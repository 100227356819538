.user-profile-content{
    display: flex;
    padding: 60px 6px 36px 26px;
    position: relative;

    .item-loader{
        width: 100%;
        min-height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .image-wrap{

        .ant-avatar{
            display: flex;
            align-items: center;
            justify-content: center;

            .anticon{
                font-size: 120px;

            }
        }
    }

    .description-wrap{
        width: 100%;
        margin-left: 50px;

        .user{
            &-function{
                text-transform: uppercase;
                margin-top: 5px;
            }

            &-role{
                position: absolute;
                top: 0;
                right: 0;
                text-transform: capitalize;
            }
        }

        .block{
            padding: 14px 0 20px;
            width: 100%;
            font-size: 16px;

            h5{
                font-weight: normal;
                margin-bottom: 5px;
            }

            & + .block{
                border-top: 1px solid var(--second-sub-text-color);
            }

            &.interest{
                font-size: 16px;
                line-height: 1.6em;
                color: var(--text-color);
            }

            a {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                color: var(--primary-color-hex);
            }

            .anticon {
                position: absolute;
                left: 0;
                font-size: 30px;
            }

            li,
            .site-link,
            .address-block{
                display: flex;
                align-items: center;
                padding-left: 40px;
                position: relative;
                margin-top: 10px;
            }

            .address-block{
                display: block;
                margin-top: 17px;

                p{
                    line-height: 1.2em;
                }
            }


        }

        .avatar-block{

            .avatar-content{
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex: 1;
            }

            .company-name{
                font-weight: bold;
            }

            a{
                font-size: 13px;
                font-weight: bold;
                text-overflow: initial;
                overflow: initial;
            }
        }

        .contacts{
            ul{
                list-style: none;
                margin: 0;
                padding: 0;
            }
        }
    }
}