.search-taxonomy-wrap{
    display: flex;

    .item-loader{
        display: flex;
        align-items: center;
        padding: 0 20px;

        .anticon-loading{
            color: var(--base-color);
        }
    }

    .search-taxonomy{
        margin-left: 20px;

        &:last-child{
            margin-right: 20px;
        }

        button{
            background: transparent;
            border-color: transparent;
            color: var(--base-color);
            display: flex;
            align-items: center;
            padding: 0 0 0 12px;
            box-shadow: none;

            .anticon{
                font-size: 36px;
            }

            &.active{
                background: rgba(0, 0, 0, 0.2);
            }
        }
    }
}

.taxonomy-terms-modal{

    z-index: 1029 !important;

    .ant-modal{
        width: 100% !important;
        top: 60px !important;
        max-width: 100%;
        padding: 0;

        .ant-modal-body{
            padding: 0;
        }

        .ant-modal-close-x{
            display: none;
        }
    }
}