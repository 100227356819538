.form-holder{
    &.registration{
        margin: 50px 0 100px;
    }
}

.registration-form{
    .legal-block{
        max-width: 300px;
        font-size: 13px;
        margin-bottom: 20px;
        line-height: 1.4em;
    }

    .language-wrap{
        position: absolute;
        bottom: 85px !important;
    }
}

.registration-form-message{
    max-width: 300px;

    .route-link{
        font-weight: bold;
        text-decoration: underline;
    }

    .instructions{
        margin: 45px 0;

        h3{
            text-transform: uppercase;
            margin-bottom: 6px;
            line-height: 1em;
            font-size: 14px;
        }

        ul{
            margin-top: 24px;
        }
    }
}