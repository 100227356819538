.search-results{
    width: 100%;

    &.product{
        & > .swiper-container{
            & > .swiper-wrapper {
                & > .swiper-slide-active {
                    margin-top: 30px !important;
                }

                & > .swiper-slide{
                    margin-top: 110px;
                }
            }


        }

    }

    &.company{
        & > .swiper-container{
            & > .swiper-wrapper {
               & > .swiper-slide-active {
                    margin-top: 30px !important;
                }
            }
        }

    }

    &-slider{
        height: 700px;

        & > .swiper-wrapper{


            & > .swiper-slide {
                height: initial;
                width: 285px;
                margin-top: 100px;
                margin-bottom: 60px;
                transition: all 0.3s ease-in-out;

                .card{
                    //margin: 0 5px;
                }

                &-active {
                    margin-top: 0;
                    margin-bottom: 0;
                }
            }
        }


        & > .slick-arrow{
            position: absolute;
            background-color: transparent !important;
            opacity: 0.7;
            bottom: 10px;
            padding: 0;

            .anticon{
                font-size: 48px !important;
            }

            &.slick-next{
                .anticon{
                    top: 2px;
                }
            }

            &.slick-next{
                right: 50%;
                transform: translateX(100px);
            }

            &.slick-prev{
                left: 50%;
                transform: translateX(-100px);
            }

            &:hover{
                background-color: transparent !important;
                opacity: 1;
            }
        }
    }
}