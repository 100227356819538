.team-invite-form{
    min-width: 600px;

    .ant-form-item-explain-error{
        text-align: left;
    }

    .remove-button{
        width: 30px;
        height: 40px;
        color: var(--sub-text-color);
        margin-left: 10px;

        &:hover{
            color: var(--text-color);
        }
    }

    .add-button{
        color: var(--primary-color-hex);
        font-size: 14px;
        display: flex;
        align-items: center;
        margin: 0 auto;
        font-weight: bold;

        .anticon{
            margin-right: 10px;
        }
    }

    .add-button,
    .remove-button{
        border: none;
        background-color: transparent;
        padding: 0;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        .anticon{
            font-size: 32px;
        }

    }

    .field-sub-group{
        display: flex;
        flex-direction: row !important;
        justify-content: center;

        & > *:not(button){
            flex: 1;
        }

        .ant-form-item{
            margin-bottom: 0 !important;
        }
    }
}