.profile-page{
    padding: 100px 0 75px;

    .actions{
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
    }

    .item-loader{
        min-height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .user-role{
        margin-top: 40px;
        font-size: 16px;

        & > b{
            color: var(--primary-color-hex);
        }
    }
}