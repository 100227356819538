.package-item{
    min-width: 276px;
    max-width: 365px;
    background-color: var(--sub-bg-color-hex);
    overflow: hidden;
    border-radius: 4px;

    &.current{
        box-shadow: 1px 3px 17px 0 rgba(0,0,0,0.18);

        .package-header{
            h3{
                color: var(--primary-color-hex);
            }
        }

        &:before{
            background-color: var(--primary-color-hex);
        }
    }

    &.off{
        opacity: 0.6;

        .details{
            a{
                color: var(--sub-text-color) !important;
            }
        }

        .anticon:not(.forbidden){
            background-color: var(--sub-text-color);
        }
    }

    &:before{
        content: "";
        display: block;
        background-color: var(--sub-text-color);
        width: 100%;
        height: 13px;
    }

    .package-header,
    .package-footer{
        height: 80px;
        text-align: center;
        display: flex;
        flex-direction: column;
    }

    .package-header{
        height: 80px;
        margin: 20px 0 30px;

        .price{
            color: var(--text-color);
            font-size: 30px;
            margin-top: 25px;

            span{
                font-size: 36px;
            }
        }
    }

    .package-rules{
        list-style: none;
        padding: 0;
        margin: 0 25px;

        li{
            padding-left: 30px;
            position: relative;
            font-size: 16px;

            &.forbidden{
                .anticon{
                    background-color: var(--default-button-color-hex);
                }
            }

            .anticon{
                width: 16px;
                height: 16px;
                position: absolute;
                left: 0;
                top: 4px;
                border-radius: 50%;
                color: var(--text-on-bg-color);
                background-color: var(--success-color);
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 20px;
            }

            & + li{
                margin-top: 15px;
            }
        }
    }

    .package-footer{
        margin: 30px 0 20px;
        display: flex;

        justify-content: flex-end;

        .details{
            a{
                color: var(--primary-color-hex);
            }
        }

        .action{
            min-height: 40px;
            margin-bottom: 15px;
        }
    }
}