%wrap-style{
    border-radius: 5px;
    background-color: var(--base-color);
    box-shadow: 1px 1px 9px 0 rgba(0,0,0,0.15);
}

.product-top-slider{
    padding-bottom: 20px;

    &-wrap{
        min-height: 600px;
        width: 100%;
        background-color: #F07E00;
        padding: 30px 0 25px;
        transition: all 0.3s ease-in-out;

        & > h2{
            margin-bottom: 34px;
            text-align: center;
            color: var(--base-color);
        }
    }

    .swiper-slide{
        width: 250px;
        margin-top: 50px;
        margin-bottom: 60px;
        padding: 0 5px;
        transition: all 0.3s ease-in-out;

        &-active{
            margin-top: 0;
            margin-bottom: 0;

            .product-card{
                z-index: 10;
                width: 120%;
                transform: translate(-8%, 0px);
                box-shadow: 1px 3px 30px 0 rgba(0,0,0,0.15);

                .product-description{
                    padding: 26px 24px;

                    h5{
                        font-size: 16px;
                    }
                }

                .product-actions{
                    &-wrap{
                        position: relative;
                        margin-bottom: 10px;
                        height: 40px;
                    }
                }
            }
        }
    }

    .slick-arrow{
        top: 40%;
        position: absolute;

        &.slick-next{
            right: 24px;
        }

        &.slick-prev{
            left: 24px;
        }
    }

    .product-card{
        overflow: hidden;
        width: 100%;
        position: relative;
        transition: all 0.3s ease-in-out;

        @extend %wrap-style;

        .product-description{
            position: relative;
            text-align: center;
            padding: 26px 12px;
            margin-bottom: 0;
            transition: all 0.3s ease-in-out;
            border-top: 1px solid var(--default-button-color-hex);
            background-color: var(--base-color);

            h5{
                color: var(--text-color);
                line-height: 1.5em;
                transition: all 0.3s ease-in-out;
            }

            .status{
                font-size: 12px;
                font-weight: bold;
                text-transform: uppercase;
                color: var(--primary-color-hex);
            }

        }

        .product-actions{
            display: flex;
            justify-content: space-between;
            align-items: center;

            &-wrap{
                position: relative;
                padding: 0 24px;
                overflow: hidden;
                height: 0;
                width: 100%;
                transition: all 0.3s ease-in-out;
            }

            .link-button{
                font-size: 13px;
                width: 110px;
                min-width: 100px;
                padding: 0 15px;
            }

            .to-favorite{
                font-size: 32px;
                color: var(--sub-text-color);
                transition: all 0.3s ease-in-out;

                &:hover{
                    color: var(--text-color);
                }
            }
        }

        .product-cover{
            padding-bottom: 100%;
            position: relative;
            display: block;
            transition: all 0.3s ease-in-out;
            z-index: 3;

            img{
                object-fit: contain;
                width: 100%;
                height: 100%;
            }

            .anticon{
                font-size: 120px;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                color: var(--sub-text-color);
            }

            & > *{
                position: absolute;
                left: 0;
                top: 0;
            }
        }
    }
}

.product-top-choice-color{
    display: flex;
    justify-content: flex-end;
    margin: 10px 30px 0 0;
}

.product-top-choice-color-modal{

    .ant-modal-body{
        padding: 60px 50px 45px;

        h4{
            margin-bottom: 20px;
            text-align: center;
        }

        button{
            span{
                margin: 0 auto;
            }
        }
    }

    .modal-actions{
        display: flex;
        justify-content: space-between;
    }
}