.favorite-button{
    cursor: pointer;

    &.icon{
        background-color: transparent;
        padding: 0;
        min-width: auto;
        height: auto;
        border-color: transparent !important;
        color: transparent;
        box-shadow: none !important;

        span{
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .anticon-spin{
            font-size: 20px !important;
        }

    }

    .anticon:not(.anticon-spin){
        font-size: 32px;
        color: var(--sub-text-color);
        transition: all 0.3s ease-in-out;
    }

    &:hover{

        .anticon{
            color: var(--text-color);
        }
    }

    &.my-favorite{
        .anticon{
            color: var(--primary-color-hex) !important;
        }

    }

    &.text{
        padding: 0 20px;

        .anticon{
            color: var(--text-color);
            top: 1px;
        }

        .anticon:not(.anticon-spin){
            font-size: 30px;
        }

        .anticon-spin{
            font-size: 18px;
            margin-right: 14px;
        }
    }
}