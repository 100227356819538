.team-remove-button{

    width: 30px;
    height: 40px;
    color: var(--sub-text-color);
    border: none;
    background-color: transparent;
    padding: 0;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    .anticon{
        font-size: 32px;
    }

    &:hover{
        color: var(--text-color);
    }

    &.cross{
        margin-left: 10px;
    }

}

.team-remove-modal{
    max-width: 420px !important;
    border-radius: 4px !important;
    overflow: hidden;

    .ant-modal{

        &-close{
            left: 10px;
            top: 10px;
        }

        &-body{
            padding: 70px 50px 30px;
            font-size: 16px;
            text-align: center;
            line-height: 1.4em;
        }

        &-footer{
            display: flex;
            justify-content: space-between;
            padding: 0 50px 40px;
            flex-direction: row-reverse;
            border-top: none;

            .ant-btn-default{
                justify-content: center;
            }
        }


    }
}