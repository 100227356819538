.favorites-wrap{
    position: relative;
    margin: 50px 0;

    & > .ant-col{
        & > .item-loader{
            min-height: 200px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .no-favorites{
        min-height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .favorite-export-wrap{
        right : 0;
        top: -95px;
        position: absolute;
    }

}