.profile-menu{
    list-style: none;
    padding: 0;
    margin: 0;

    li{
        font-size: 16px;
        line-height: 1.6em;

        a{
            display: block;
            color: var(--sub-text-color);

            &.active{
                color: var(--text-color);
            }
        }

        & + li{
            margin-top: 30px;
        }
    }
}