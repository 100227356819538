$primary-font: 'Lato', sans-serif;
$primary-font-size: 14px;
$primary-line-height: 1.6em;

$base-color: #ffffff;

$primary-color: #CC0022;

$primary-bg-color: #e9e9e9;
$sub-bg-color: #f4f4f4;

$primary-text-color: #000000;
$primary-sub-text-color : #888888;
$second-sub-text-color : #dddddd;
//$primary-text-on-bg-color: #FFFFFF;

$default-button-color : #dddddd;
$default-badge-color : #666666;

$default-shapes-color : #e9e9e9;

$error-color: #E90808;
$success-color: #35B40C;
$warning-color: #FFD306;

$analytics-showroom-views-color: #1E9FFF;
$analytics-showroom-favorites-color: #8E34D4;
$analytics-product-views-color: #339A11;
$analytics-product-favorites-color: #FFA400;
$analytics-document-views-color: #06D0E5;
$analytics-video-views-color: #E90808;

$animation-speed: 0.3s;


