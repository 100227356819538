.product-page{
    margin-top: -60px;
    min-height: 100vh;
    position: relative;

    .to-favorite{
        position: absolute;
        right: 30px;
        top: 30px;
    }

    .product-media-wrap{
        padding: 70px 80px 0;
        height: 100vh;
    }

    .product-content-wrap{
        height: calc(100vh - 125px);
        width: 100%;
        padding: 50px 0;
        margin-bottom: 125px;
        overflow: auto;
        box-shadow: -1px 5px 9px 0 rgba(0,0,0,0.24);
    }

    &-close{
        position: absolute;
        left: 30px;
        top: 30px;
        border: none;
        background: none;
        font-size: 14px;
        color: var(--sub-text-color);
        display: flex;
        align-items: center;
        text-transform: uppercase;
        outline: none;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        z-index: 11;

        &:hover{
            color: var(--text-color);
        }

        .anticon{
            font-size: 36px;
        }
    }
}