.favorite-header{
    &-wrap{
        background-color: var(--base-color);
        min-height: 180px;
        position: relative;

        display: flex;

        h2{
            font-size: 20px;
            text-align: center;
            margin-top: 60px;
        }

        .favorite-menu-bar{
            position: absolute;
            bottom: 0;
        }
    }
}