.product-media-slider{
    width: 100%;
    display: flex;
    align-items: center;
    height: 100%;
    position: relative;

    .ant-carousel{
        width: 100%;
        top: -8%;
        position: relative;

        .slick-slider{
            position: initial;

            .slick-track{
                display: flex;
                align-items: center;
            }
        }

        .media-slide{
            max-height: 550px;
            padding: 0 40px;

            img{
                object-fit: contain;
                display: inline-block;
            }
        }
    }

    .media-slide{
        display: flex;
        justify-content: center;
        position: relative;

        .delete-slide{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            align-items: center;
            color: var(--base-color);

            .anticon-loading{
                font-size: 22px !important;
                margin-right: 10px;
                color: var(--base-color);
            }

            .anticon{
                font-size: 30px;
            }
        }
    }

    .slick-dots{

        &.slick-dots-bottom{
            bottom: 24px;
        }

        li{
            width: 15px !important;
            height: 15px !important;
            margin-right: 10px !important;
            margin-left: 10px !important;

            &.slick-active{
                & > button{
                    background: var(--primary-color-hex) !important;
                }
            }

            & > button{
                border-radius: 50% !important;
                width: inherit !important;
                height: inherit !important;
                opacity: 1 !important;

                background: var(--default-button-color-hex) !important;

                &:hover{
                    background: var(--primary-color-hex) !important;
                    opacity: 0.3 !important;
                }
            }
        }
    }

    &-control{
        position: absolute;
        display: flex;
        bottom: 65px;
        left: 0;
        right: 0;
        justify-content: center;

        .possibility-text{
            position: absolute;
            bottom: -40px;
            color: var(--sub-text-color);

            b{
                color: var(--primary-color-hex);
            }
        }

        .additional-control{
            margin-left: 50px;
        }

        .label{
            color: var(--sub-text-color);
            margin-bottom: 15px;
        }

        .image-control-button{
            width: 125px;
            height: 125px;
            padding: 0;
            border-radius: 5px;
            outline: none;
            background-color: var(--base-color);
            color: var(--sub-text-color);
            border: 1px solid var(--default-button-color-hex);
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            overflow: hidden;

            &:not(.empty){
                box-shadow: 1px 3px 10px 0 rgba(0,0,0,0.18);

                &:hover,
                &.active{
                    border: 1px solid rgba(var(--primary-color), 0.43);
                    box-shadow: 1px 3px 10px 0 rgba(var(--primary-color), 0.28);
                }
            }

            &.empty{
                background-color: var(--default-shapes-color);
                border: 1px dashed var(--sub-text-color);

                &:hover{
                    border-color: var(--primary-color-hex);
                }
            }

            img{
                max-width: 100%;
                object-fit: contain;
                max-height: 100%;
            }

            .empty-text{
                display: flex;
                flex-direction: column;
                font-weight: bold;

                .anticon{
                    font-size: 38px;
                }
            }
        }

        .additional-control-list{
            list-style: none;
            margin: 0;
            padding: 0;

            display: flex;

            li + li{
                margin-left: 12px;
            }
        }

    }

    .product-slider-dots{
        list-style: none;
        padding: 0;
        display: flex;
        justify-content: center;

        &-wrap{
            position: absolute;
            bottom: 24px;
            left: 0;
            right: 0;
        }


        li{
            width: 15px !important;
            height: 15px !important;
            margin-right: 10px !important;
            margin-left: 10px !important;

            button{
                outline: none;
                padding: 0;
                cursor: pointer;
                color: transparent;
                border: none;
            }

            &.active{
                & > button{
                    background: var(--primary-color-hex) !important;
                }
            }

            & > button{
                border-radius: 50% !important;
                width: inherit !important;
                height: inherit !important;
                opacity: 1 !important;

                background: var(--default-button-color-hex) !important;

                &:hover{
                    background: var(--primary-color-hex) !important;
                    opacity: 0.3 !important;
                }
            }
        }
    }
}