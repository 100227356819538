.product-content-wrap{

    background-color: var(--base-color);

    .float-block{
        background-color: var(--base-color);
        border-top: 1px solid var(--second-sub-text-color);
        position: absolute;
        bottom: 0;
        left: 0;
        height: 125px;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0 50px;
        justify-content: space-between;
        box-shadow: -1px 7px 9px 0 rgba(0,0,0,0.24);

        .ant-btn{
            span{
                margin: 0 auto;
            }
        }
    }
}

.product-content{
    padding: 0 50px;

    h5{
        margin-top: 10px;
    }

    .label{
        font-size: 14px;
        color: var(--sub-text-color);
        margin: 0;
        line-height: 1.2em;
    }

    .text-block{
        margin-top: 20px;
    }

    .description{
        font-size: 16px;
        line-height: 1.5em;
        white-space: pre-line;
        overflow-wrap: break-word;
    }

    .buy-block{
        margin-top: 40px;
    }

    .float-block{

        &.action{
            .ant-btn-default{
                justify-content: center;
            }
        }

        &.company{

            & > div{
                flex: 1;
            }

            .avatar-content{
                display: flex;
                flex: 1;
                align-items: center;
            }

            .company-name{
                font-size: 16px;
                font-weight: bold;
                color: var(--text-color);
                flex: 1;
                padding-right: 10px;
            }

            a{
                color: var(--primary-color-hex);
                font-size: 13px;
                font-weight: bold;
                transition: all 0.3s ease-in-out !important;

                &:hover{
                    color: var(--text-color);
                }
            }

        }

        .label{
            margin-bottom: 8px;
        }
    }


}