.showroom-header-image{
    width: 100%;
    height: 380px;
    max-height: 380px;
    background-color: var(--primary-bg-color-hex);
    position: relative;

    .no-image-wrap{
        position: absolute;
        font-size: 180px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: var(--sub-text-color);
    }

    .image-wrap{
        width: 100%;
        height: 100%;
        overflow: hidden;

        img{
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

}