.product-media-cover{
    text-align: center;
    position: relative;

    img{
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
    }

    .ant-upload{
        cursor: pointer;
        height: 100%;

    }

    .no-image{
        width: 500px;
        height: 500px;
        background-color: var(--default-shapes-color);
        font-size: 100px;
        color: var(--sub-text-color);
        border: 1px dashed var(--sub-text-color);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 5px;

        .label{
            font-size: 14px;
            line-height: 1.3em;
            text-align: center;

            b{
                display: block;
            }
        }
    }

    button{
        position: absolute !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

}