.showroom-status-switcher{

    background-color: var(--base-color);

    .switcher-label{
        margin-bottom: 10px;
        font-size: 16px;
        width: 100%;
        overflow: hidden;
        transition: all 0.25s ease-in-out;
        text-overflow: ellipsis;
        white-space: nowrap;

        & > *{
            text-align: center;
            transition: all 0.25s ease-in-out;
        }
    }

    .ant-switch{
        width: 170px;
        height: 38px;
        background-color: var(--default-button-color-hex);
        overflow: hidden;
        transition: all 0.25s ease-in-out;

        &:focus{
            box-shadow: none;
        }

        &-inner{
            color: var(--sub-text-color);
            font-size: 14px;
            text-transform: uppercase;
            position: absolute;
            top: 8px;
            overflow: hidden;
            width: 85px;
            margin: 0 !important;
            transition: all 0.2s ease-in-out;
            left: calc(100% - 85px - 4px);
        }

        &-checked{
            background-color: var(--success-color);

            .ant-switch-inner{
                left: 4px;
                color: var(--base-color);
            }

            .ant-switch-handle{
                left: calc(100% - 65px - 4px);
            }
        }

        &-handle{
            top: 4px;
            left: 4px;
            width: 65px;
            height: 30px;
            z-index: 5;

            &:before{
                border-radius: 20px;
            }
        }

    }

    &-modal{

        .ant-modal-body{
            padding: 70px 50px 30px;
            font-size: 16px;
        }

        .ant-modal-footer{
            display: flex;
            justify-content: space-between;
            padding: 0 50px 40px;
            flex-direction: row-reverse;
            border-top: none;

            .ant-btn{
                margin: 0;

                &-default{
                    justify-content: center;
                }
            }
        }
    }
}

.sidebar:not(.open){

    .showroom-status-switcher{

        & > .switcher-label{
            width: 0;
            margin-bottom: 0;
        }

        .ant-switch{
            width: 40px;
            height: 24px;
            margin-bottom: 20px;

            &-inner{
                width: 0;
            }

            &-checked{

                .ant-switch-handle{
                    left: calc(100% - 20px - 2px);
                }
            }

            &-handle{
                top: 2px;
                left: 2px;
                width: 20px;
                height: 20px;

                &:before{
                    border-radius: 50%;
                }
            }

        }

    }
}