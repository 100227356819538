.analytics-table{

    th,td{
        border-bottom: 1px solid #C3C3C3 !important;

        &:before{
            display: none;
        }

        &:first-child{
            padding-left: 0;
        }
    }

    td{
        &:not(:first-child){
            font-size: 18px;
        }
    }

    th{
        font-size: 12px;
        color: var(--sub-text-color) !important;
        text-transform: uppercase;
        background-color: transparent !important;


        span{
            margin-right: 5px;
        }

        p{
            display: flex;
            align-items: center;
        }
    }

    .badge-user{
        display: flex;
        align-items: center;

        .anticon{
            font-size: 40px !important;
        }
    }

    .ant-spin{
        &-dot-item{
            background: var(--primary-color-hex);
        }
    }

    .ant-table{
        background-color: transparent;

        .anticon{
            font-size: 28px;

            &.check-icon{
                color: var(--success-color);
            }
        }
    }

}