.activation-account{

    &.loading{
        text-align: center;
    }

    .item-loader{
        height: 65px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    & > *:last-child{
        display: inline-block;
        margin-top: 24px;
    }
}