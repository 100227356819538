.team-menu{

    .popover-button {
        height: 40px;
        width: 40px;
        border: none;
        border-radius: 50%;
        font-size: 24px;
        background-color: transparent;
        color: var(--sub-text-color);
        transition: all 0.3s ease-in-out;
        cursor: pointer;
    }

}
